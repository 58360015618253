/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import HistoryItem from "views/client/tokens/components/HistoryItem";
import Card from "components/card/Card.js";

// Assets
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { ListTokens } from "services/tokens";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ModalOrder } from "components/modalOrder";
import { ListUniqueTokens } from "services/tokens";
import { lightFormat } from "date-fns";
import { toPrice } from "utils/masks";

export default function Tokens() {
    const { user } = useAuthContext();
    const textColor = useColorModeValue("brands.900", "white");
    const bgItem = useColorModeValue(
      { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
      { bg: "navy.700", boxShadow: "unset" }
    );

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');
    const [listHash, setListHash] = useState([]);
    const [totalPagesHash, setTotalPagesHash] = useState(1);
    const [currentPageHash, setCurrentPageHash] = useState(1);
    const [searchTxtHash, setSearchTxtHash] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [infoToken, setInfoToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [frame, setFrame] = useState(1);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getList();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [currentPage, searchTxt]);

    useEffect(() => {
        if (frame === 2) {
            const timeOutId = setTimeout(() => {
                getListUnique();
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [currentPageHash, searchTxtHash, frame]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePageChangeHash = (pageNumber) => {
        setCurrentPageHash(pageNumber);
    };

    const getList = async () => {
        const response = await ListTokens(user?.user?.id, searchTxt, currentPage, user?.user?.typeUser, user?.token);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
    };

    const getListUnique = async () => {
        const response = await ListUniqueTokens(infoToken?.id, searchTxtHash, currentPageHash, user?.token);
        setListHash(response?.result?.list || []);
        setTotalPagesHash(response?.result?.qtdPages || 1);
    };

    const handleClickContinueOrder = (item) => {
        if (item.status === 'NO_PAID') {
            setIsOpen(true);
            setInfoToken(item);
            setStep(3);
        }
        if (item.status === 'NO_SIGNATURE') {
            setIsOpen(true);
            setInfoToken(item);
            setStep(2);
        }
    };

    const renderStatus = (status) => {
        if (status === 'NO_SIGNATURE') {
            return 'Não assinado';
        }
        if (status === 'NO_PAID') {
            return 'Não pago';
        }
        if (status === 'PAID') {
            return 'Pago';
        }
        if (status === 'AUTHORIZED') {
            return 'Pagamento em processamento';
        }
        if (status === 'CANCELED') {
            return 'Pagamento cancelado';
        }
        if (status === 'ABORT') {
            return 'Pedido cancelado pelo cliente';
        }
        return '';
    };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
            {frame === 1 ? (
                <Card p='0px 0px 20px 0px'>
                    <SearchBar
                        style={{ marginLeft: 20, marginTop: 20 }}
                        placeholder="Buscar token"
                        value={searchTxt}
                        setValue={setSearchTxt}
                    />
                    {(list || []).map((v) => (
                        <HistoryItem
                            name={v.token_name}
                            author={`Situação: ${renderStatus(v.status)}`}
                            info={`Total de tokens adquiridos: ${v.qtd_total || v.qtd}`}
                            date={v.created_at}
                            image={v.token_logo}
                            price={v.total_value}
                            key={v.id}
                            onClick={() => {
                                if (v.status === 'PAID') {
                                    setInfoToken(v);
                                    setFrame(2);
                                }
                            }}
                            handleClickContinueOrder={handleClickContinueOrder}
                            getList={getList}
                            item={v}
                        />
                    ))}
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Card>
            ) : (
                <Card p='0px 0px 20px 0px'>
                    <Flex justifyContent="space-between" padding="20px 20px 0px 20px" mb="20px">
                        <SearchBar
                            placeholder="Buscar hash"
                            value={searchTxtHash}
                            setValue={setSearchTxtHash}
                        />
                        <Button
                            onClick={() => {
                                setFrame(1);
                                setListHash([]);
                                setCurrentPageHash(1);
                                setTotalPagesHash(1);
                                setSearchTxtHash('');
                            }}
                            w='200px'
                            h='44px'
                            variant='brand'
                        >
                            Ver todos os tokens
                        </Button>
                    </Flex>
                    <Flex
                        direction='flex'
                        justifyContent="space-between"
                        w="90%"
                        alignSelf="center"
                        mb="10px"
                        pl="20px"
                    >
                        {["DATA DE COMPRA", "VALOR PAGO", "QTD TOKENS"]?.map((v) => (
                            <Text
                                color={textColor}
                                fontSize={{
                                    base: "md",
                                }}
                                fontWeight='bold'
                                key={v}
                            >
                                {v}
                            </Text>
                        ))}
                    </Flex>
                    {(listHash || []).map((v) => (
                        <Card
                            _hover={bgItem}
                            bg='transparent'
                            boxShadow='unset'
                            // px='24px'
                            // py='21px'
                            transition='0.2s linear'
                            style={{ cursor: 'pointer' }}
                            key={v.id}
                            w="90%"
                            alignSelf="center"
                        >
                            <Flex
                                direction='flex'
                                justifyContent="space-between"
                                w="100%"
                                alignSelf="center"
                            >
                                <Text
                                    color={textColor}
                                    fontSize={{
                                        base: "md",
                                    }}
                                    fontWeight='bold'
                                >
                                    {lightFormat(new Date(v.created_at), 'dd/MM/yyyy HH:mm')}
                                </Text>
                                <Text
                                    color={textColor}
                                    fontSize={{
                                        base: "md",
                                    }}
                                    fontWeight='bold'
                                    mr="70px"
                                >
                                    {`R$ ${toPrice(v.token_value_unity * v.token_qtd_order, 2)}`}
                                </Text>
                                <Text
                                    color={textColor}
                                    fontSize={{
                                        base: "md",
                                    }}
                                    fontWeight='bold'
                                >
                                    {v.token_qtd_order}
                                </Text>
                            </Flex>
                        </Card>
                    ))}
                    <Pagination
                        currentPage={currentPageHash}
                        totalPages={totalPagesHash}
                        onPageChange={handlePageChangeHash}
                        limit={30}
                    />
                </Card>
            )}
        </Flex>
        <ModalOrder isOpen={isOpen} setIsOpen={setIsOpen} infoToken={infoToken} setInfoToken={setInfoToken} getList={getList} isLoading={isLoading} setIsLoading={setIsLoading} step={step} setStep={setStep} />
    </Box>
  );
}
