import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineGeneratingTokens,
  MdLocalOffer,
} from "react-icons/md";

// Clients Imports
import OffersClient from "views/client/offers";
import TokensClient from "views/client/tokens";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import Users from "views/admin/users";
import Tokens from "views/admin/tokens";
import TokensSale from "views/admin/tokensSale";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signUp";
import forgotPasswordCentered from "views/auth/forgotPassword";

export const formatRoutes = () => {
    const isAuthenticated = window.localStorage.getItem('@dsstoken_authenticated');

    const routesAuth = [
        {
            name: "Sign In",
            layout: "/auth",
            path: "/sign-in",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: SignInCentered,
            hidden: true,
        },
        {
            name: "Sign Up",
            layout: "/auth",
            path: "/sign-up",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: SignUpCentered,
            hidden: true,
        },
        {
            name: "Recover Password",
            layout: "/auth",
            path: "/forgot-password",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: forgotPasswordCentered,
            hidden: true,
        },
    ];

    const routesAdm = [
        {
          name: "Dashboard",
          layout: "/admin",
          path: "/default",
          icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
          component: MainDashboard,
        },
        {
          name: "Tokens",
          layout: "/admin",
          icon: <Icon as={MdOutlineGeneratingTokens} width='20px' height='20px' color='inherit' />,
          path: "/tokens",
          component: Tokens,
        },
        {
          name: "Tokens vendidos",
          layout: "/admin",
          icon: <Icon as={MdOutlineGeneratingTokens} width='20px' height='20px' color='inherit' />,
          path: "/vendas",
          component: TokensSale,
        },
        {
          name: "Usuários",
          layout: "/admin",
          icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
          path: "/users",
          component: Users,
        },
        // {
        //   name: "Configurações de perfil",
        //   layout: "/admin",
        //   path: "/profile",
        //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
        //   component: Profile,
        //   hidden: true,
        // },
    ];

    const routesClient = [
        {
            name: "Ofertas ativas",
            layout: "/client",
            path: "/offers",
            icon: <Icon as={MdLocalOffer} width='20px' height='20px' color='inherit' />,
            component: OffersClient,
        },
        {
            name: "Meus tokens",
            layout: "/client",
            path: "/tokens",
            icon: <Icon as={MdOutlineGeneratingTokens} width='20px' height='20px' color='inherit' />,
            component: TokensClient,
        },
        {
            name: "Configurações de perfil",
            layout: "/client",
            path: "/profile",
            icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
            component: Profile,
            hidden: true,
        },
    ];

    if (isAuthenticated) {
        const dataUser = JSON.parse(isAuthenticated);
        return [
            ...routesAuth,
            ...(dataUser?.user?.typeUser === 'managers' ? routesAdm : routesClient),
        ];
    }
    
    return routesAuth;
}
