import { api } from './api';

export async function GeneratePix(idOrder, token) {
    try {
        const response = await api.post('/paygo/pix', { idOrder }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function VerifyPix(idOrderPayment, token) {
    try {
        const response = await api.get(`/paygo/pix?idOrderPayment=${idOrderPayment}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CancelOrder(idOrder, token) {
    try {
        const response = await api.patch('/paygo/abort', { idOrder }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function PagseguroAvulso(idToken, idUser, qtd, token) {
    try {
        const response = await api.post('/paygo/avulso', { idToken, idUser, qtd }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
