/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import ClientLayout from 'layouts/client';
import LoadingLayout from 'views/loading';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { AuthProvider } from 'contexts/SidebarContext';
import { useAuthContext } from 'contexts/SidebarContext';

const Routes = () => {
    const { user } = useAuthContext();
    const dataURL = window.location.href.split('?id=')[1];
    const getID = dataURL ? dataURL.split('&qtd=')[0] : '';
    const getQTD = dataURL ? dataURL.split('&qtd=')[1] : '';
    if (getID && getQTD) {
        window.sessionStorage.setItem('@dsstoken_idToken', getID);
        window.sessionStorage.setItem('@dsstoken_qtdToken', getQTD);
    }

    const [openSession, setOpenSession] = useState(false);

    useEffect(() => {
        if (!openSession) {
            verifyUser();
        }
    }, [user]);

    const verifyUser = () => {
        try {
            const dataUserSave = window.localStorage.getItem('@dsstoken_authenticated');
            const dataUserParse = JSON.parse(dataUserSave);
            if (dataUserParse.tokenRefresh && user) {
                setOpenSession(true);
            }
        } catch (error) {
            setOpenSession(true);
        }
    };

    if (!openSession) {
        return (
            <>
                <Route path={`/loading`} component={LoadingLayout} />
                <Redirect from='/' to='/loading' />
            </>
        );
    }
    if (user?.user?.typeUser === 'managers') {
        return (
            <>
                <Route path={`/admin`} component={AdminLayout} />
                <Redirect from='/' to='/admin/default' />
            </>
        );
    }
    if (user?.user?.typeUser === 'users') {
        return (
            <>
                <Route path={`/client`} component={ClientLayout} />
                <Redirect from='/' to='/client/offers' />
            </>
        );
    }
    return (
        <>
            <Route path={`/auth`} component={AuthLayout} />
            <Redirect from='/' to='/auth/sign-in' />
        </>
    )
}

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
            <AuthProvider>
                <ThemeEditorProvider>
                    <HashRouter>
                        <Switch>
                            <Routes />
                        </Switch>
                    </HashRouter>
                </ThemeEditorProvider>
            </AuthProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
