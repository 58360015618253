import {
    Box,
    Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
  BtnClearFormatting,
  BtnRedo,
  BtnStrikeThrough,
  BtnUnderline,
  BtnUndo,
  Separator,
} from 'react-simple-wysiwyg';
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
import Dropzone from "views/admin/profile/components/Dropzone";
import { MdGeneratingTokens, MdClose } from "react-icons/md";
import { FaFileAlt } from "react-icons/fa";
import { lightFormat } from "date-fns";
import { moneyMask } from "utils/masks";
import { toPrice } from "utils/masks";
import { CreateToken } from "services/tokens";
import { priceUnmask } from "utils/masks";
import { useAuthContext } from "contexts/SidebarContext";
import { DeleteToken } from "services/tokens";
import { BlockToken } from "services/tokens";
import { EditToken } from "services/tokens";
import Pagination from "components/pagination";
import { getNameWithExtensionFromUrl } from "utils/masks";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { percentageMask } from "utils/masks";
import { convertPercentageToDecimal } from "utils/masks";

export default function ColumnsTable(props) {
    const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt } = props;

    const { user } = useAuthContext();
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
  
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const tableInstance = useTable(
        {
        columns,
        data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 5;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const brandColor = useColorModeValue("brand.500", "white");
    const bg = useColorModeValue("gray.100", "navy.700");
    const borderColorArchive = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    const [iconToken, setIconToken] = useState(null);
    const [characteristics, setCharacteristics] = useState('');

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
        setIconToken('');
    };

    const validationSchema = Yup.object().shape({
        sigla: Yup.string().required('Sigla é obrigatória'),
        name: Yup.string().required('Nome é obrigatório'),
        quantity: Yup.string().required('Quantidade é obrigatória'),
        unitPrice: Yup.string().required('Valor é obrigatório'),
        minimumValue: Yup.string().required('Valor mínimo de compra é obrigatório'),
        remunerationPercentage: Yup.string().required('Porcentagem é obrigatória'),
        remunerationDeadline: Yup.string().required('Prazo de remuneração é obrigatória'),
        startDateOffers: Yup.string(),
        endDateOffers: Yup.string(),
        contract: Yup.array().min(1, 'Adicione o contrato do token').required('Adicione o contrato do token.'),
        gallery: Yup.array().notRequired(),
        archives: Yup.array().notRequired(),
        monthsLifetime: Yup.string(),
    });

    const onClickEdit = (item) => {
        setIconToken(item.logo);
        setCharacteristics(item.characteristics || '');
        setInfoRow(item);
        setIsOpen(true);
    };

    const onClickBlock = async (item) => {
        await BlockToken(item.id, user?.token);
        getList();
    };

    const onClickDelete = async (item) => {
        await DeleteToken(item.id, user?.token);
        getList();
    };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex justifyContent="space-between" padding="0px 20px 0px 20px" mb="20px">
            <SearchBar
                placeholder="Buscar token"
                value={searchTxt}
                setValue={setSearchTxt}
            />
            <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant='brand'>
                Criar token
            </Button>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {infoRow ? 'Editar token' : 'Criar token'}
                    <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} um token`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Dropzone
                        minH="120px"
                        w="120px"
                        borderRadius="50%"
                        mb="24px"
                        onImageSelect={(file) => setIconToken(file[0] || '')}
                        multiple={false}
                        accept="image/png, image/jpeg, image/jpg"
                        content={
                            <Box>
                                {iconToken ? (
                                    <Image src={iconToken} minW='120px' minH='120px' borderRadius="50%" />
                                ) : (
                                    <Icon as={MdGeneratingTokens} w='60px' h='60px' color={brandColor} />
                                )}
                            </Box>
                        }
                    />
                    <Formik
                        initialValues={{
                            sigla: infoRow?.sigla ? infoRow.sigla : '',
                            name: infoRow?.name ? infoRow.name : '',
                            quantity: infoRow?.qtd ? infoRow.qtd : '',
                            unitPrice: infoRow?.value ? `R$ ${toPrice(infoRow.value, 2)}` : '',
                            minimumValue: infoRow?.minimum_value ? `R$ ${toPrice(infoRow.minimum_value, 2)}` : '',
                            remunerationPercentage: infoRow?.percentage_of_remuneration ? String(infoRow.percentage_of_remuneration).replace('.', ',') : '',
                            remunerationDeadline: infoRow?.remuneration_period ? infoRow.remuneration_period : '',
                            startDateOffers: infoRow?.start_date_offers ? infoRow.start_date_offers.substring(0, 16) : '',
                            endDateOffers: infoRow?.end_date_offers ? infoRow.end_date_offers.substring(0, 16) : '',
                            contract: infoRow?.contract ? [infoRow.contract] : [],
                            gallery: infoRow?.images ? infoRow.images.split(',') : [],
                            archives: infoRow?.files ? infoRow.files.split(',') : [],
                            monthsLifetime: infoRow?.months_lifetime || '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            if (Number(values.remunerationPercentage) >= 100) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'Adicione uma porcentagem inferir a 100' });
                                helpers.setSubmitting(false);
                                return null;
                            }
                            if (!iconToken) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'Foto é obrigatória' });
                                helpers.setSubmitting(false);
                                return null;
                            }
                            try {
                                const response = !infoRow?.id ? await CreateToken(
                                    iconToken,
                                    values.sigla,
                                    values.name,
                                    Number(values.quantity),
                                    priceUnmask(values.unitPrice),
                                    priceUnmask(values.minimumValue),
                                    convertPercentageToDecimal(values.remunerationPercentage),
                                    values.remunerationDeadline,
                                    values.contract[0],
                                    characteristics || '',
                                    values?.gallery?.length > 0 ? values.gallery.join(',') : '',
                                    values?.archives?.length > 0 ? values.archives.join(',') : '',
                                    values.startDateOffers || '',
                                    values.endDateOffers || '',
                                    values.monthsLifetime && values.remunerationDeadline === 'temporal' ? values.monthsLifetime : null,
                                    user?.token,
                                ) : await EditToken(
                                    iconToken,
                                    values.sigla,
                                    values.name,
                                    Number(values.quantity),
                                    priceUnmask(values.unitPrice),
                                    priceUnmask(values.minimumValue),
                                    convertPercentageToDecimal(values.remunerationPercentage),
                                    values.remunerationDeadline,
                                    values.contract[0],
                                    characteristics || '',
                                    values?.gallery?.length > 0 ? values.gallery.join(',') : '',
                                    values?.archives?.length > 0 ? values.archives.join(',') : '',
                                    values.startDateOffers || '',
                                    values.endDateOffers || '',
                                    values.monthsLifetime && values.remunerationDeadline === 'temporal' ? values.monthsLifetime : null,
                                    infoRow?.id,
                                    user?.token,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    toast(response?.message || 'Token salvo com sucesso!');
                                    helpers.setSubmitting(false);
                                    onClose();
                                    getList();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid templateColumns='repeat(2, 1fr)' gap='16px' mb="24px">
                                    <Field name='sigla'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.sigla && form.touched.sigla}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Sigla do token<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Sigla'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.sigla}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='name'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.name && form.touched.name}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Nome do token<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Nome'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='quantity'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.quantity && form.touched.quantity}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Quantidade disponível<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Quantidade'
                                                    type="number"
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                        if (txt.target.value.length < 10) {
                                                            props.handleChange({
                                                                target: {
                                                                    name: 'quantity',
                                                                    value: txt.target.value,
                                                                }
                                                            });
                                                        }
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.quantity}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='unitPrice'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.unitPrice && form.touched.unitPrice}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Valor unitário<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Valor'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                        const formatMoney = moneyMask(txt.target.value);
                                                        props.handleChange({
                                                            target: {
                                                                name: 'unitPrice',
                                                                value: formatMoney,
                                                            }
                                                        });
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.unitPrice}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='minimumValue'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.minimumValue && form.touched.minimumValue}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Valor mínimo de compra<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Valor'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                        const formatMoney = moneyMask(txt.target.value);
                                                        props.handleChange({
                                                            target: {
                                                                name: 'minimumValue',
                                                                value: formatMoney,
                                                            }
                                                        });
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.minimumValue}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='remunerationPercentage'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.remunerationPercentage && form.touched.remunerationPercentage}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Percentual de remuneração (%)<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Porcentagem'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                        const formatMoney = percentageMask(txt.target.value);
                                                        props.handleChange({
                                                            target: {
                                                                name: 'remunerationPercentage',
                                                                value: formatMoney,
                                                            }
                                                        });
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.remunerationPercentage}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='remunerationDeadline'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.remunerationDeadline && form.touched.remunerationDeadline}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Prazo de remuneração<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Select
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Selecione o prazo'
                                                    fontWeight='500'
                                                    size='lg'
                                                >
                                                    <option value='vitalicio'>Vitalício</option>
                                                    <option value='temporal'>Temporal</option>
                                                </Select>
                                                <FormErrorMessage>{form.errors.remunerationDeadline}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    {props.values.remunerationDeadline === 'temporal' ? (
                                        <Field name='monthsLifetime'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.monthsLifetime && form.touched.monthsLifetime}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='sm'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        Prazo (meses)<Text color={brandStars}>*</Text>
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='sm'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Prazo'
                                                        type="number"
                                                        fontWeight='500'
                                                        size='lg'
                                                        onChange={(txt) => {
                                                            if (txt.target.value.includes('-')) {
                                                                return null;
                                                            }
                                                            if (txt.target.value.length < 3) {
                                                                props.handleChange({
                                                                    target: {
                                                                        name: 'monthsLifetime',
                                                                        value: txt.target.value,
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <FormErrorMessage>{form.errors.monthsLifetime}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    ) : null}
                                    <Field name='startDateOffers'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.startDateOffers && form.touched.startDateOffers}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Data inicial da oferta<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='dd/MM/yyyy HH:mm'
                                                    fontWeight='500'
                                                    size='lg'
                                                    type="datetime-local"
                                                />
                                                <FormErrorMessage>{form.errors.startDateOffers}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='endDateOffers'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.endDateOffers && form.touched.endDateOffers}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Data final da oferta<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='dd/MM/yyyy HH:mm'
                                                    fontWeight='500'
                                                    size='lg'
                                                    type="datetime-local"
                                                />
                                                <FormErrorMessage>{form.errors.endDateOffers}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                <EditorProvider>
                                    <Editor value={characteristics} onChange={(e) => setCharacteristics(e.target.value)} containerProps={{ style: { minHeight: 200 } }}>
                                        <Toolbar>
                                            <BtnUndo />
                                            <BtnRedo />
                                            <Separator />
                                            <BtnBold />
                                            <BtnItalic />
                                            <BtnUnderline />
                                            <BtnStrikeThrough />
                                            <BtnClearFormatting />
                                        </Toolbar>
                                    </Editor>
                                </EditorProvider>
                                <Field name='contract'>
                                    {({ form }) => (
                                        <FormControl isInvalid={form.errors.contract && form.touched.contract}>
                                            <Flex display="flex" flexDirection="column" maxW="100%">
                                                <Dropzone
                                                    minH="60px"
                                                    w="100%"
                                                    borderRadius="10px"
                                                    mt="24px"
                                                    onImageSelect={(file) => {
                                                        form.setFieldValue('contract', file);
                                                    }}
                                                    accept="application/pdf,.doc,.docx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.presentationml.presentation,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.oasis.opendocument.text,.odt,application/vnd.oasis.opendocument.spreadsheet,.ods,application/vnd.oasis.opendocument.presentation,.odp,text/plain,.txt"
                                                    content={
                                                        <Box display="flex" flexDirection="column" gap="10px">
                                                            {form?.values?.contract?.length > 0 ? (
                                                                <Text color='blue.500' fontSize='xs' fontWeight='400'>{getNameWithExtensionFromUrl(String(form?.values?.contract[0]))}</Text>
                                                            ) : (
                                                                <>
                                                                    <Text color='blue.500' fontSize='xs' fontWeight='400'>Clique aqui para selecionar o CONTRATO.</Text>
                                                                    <Text color='secondaryGray.700' fontSize='10px' fontWeight='400'>Formatos aceitos: PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, ODT, ODS, ODP, TXT</Text>
                                                                </>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            </Flex>
                                            <FormErrorMessage>{form.errors.contract}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='gallery'>
                                    {({ form }) => (
                                        <Flex display="flex" flexDirection="column" maxW="100%" mb={form?.values?.gallery?.length > 0 ? "24px" : '0px'}>
                                            <Dropzone
                                                minH="60px"
                                                w="100%"
                                                borderRadius="10px"
                                                mt="24px"
                                                onImageSelect={(files) => {
                                                    const newGallery = form?.values?.gallery?.length > 0 ? [...form.values.gallery, ...files] : files;
                                                    form.setFieldValue('gallery', newGallery);
                                                }}
                                                accept="image/png, image/jpeg, image/jpg, image/avif, image/webp"
                                                multiple
                                                content={
                                                    <Box display="flex" flexDirection="column" gap="10px">
                                                        <Text color='blue.500' fontSize='xs' fontWeight='400'>Clique aqui para selecionar até 10 imagens.</Text>
                                                        <Text color='secondaryGray.700' fontSize='10px' fontWeight='400'>Formatos aceitos: PNG, JPEG, JPG, AVIF, WEBP</Text>
                                                    </Box>
                                                }
                                            />
                                            <Grid templateColumns='repeat(3, 1fr)' gap='16px' mt="24px">
                                                {form?.values?.gallery?.length > 0 ? (
                                                    form.values.gallery.map((photo, index) => (
                                                        <Flex key={index} position="relative" minW='120px' maxW="120px" minH='120px' maxH="120px">
                                                            <Image src={photo} minW='120px' maxW="120px" minH='120px' maxH="120px" borderRadius="10px" />
                                                            <Icon
                                                                onClick={() => {
                                                                    const updatedGallery = form.values.gallery.filter((_, i) => i !== index);
                                                                    form.setFieldValue('gallery', updatedGallery);
                                                                }}
                                                                as={MdClose}
                                                                cursor="pointer"
                                                                w='20px'
                                                                h='20px'
                                                                color="red.600"
                                                                position="absolute"
                                                                top="2px"
                                                                right="2px"
                                                                zIndex={2}
                                                            />
                                                        </Flex>
                                                    ))
                                                ) : null}
                                            </Grid>
                                        </Flex>
                                    )}
                                </Field>
                                <Field name='archives'>
                                    {({ form }) => (
                                        <Flex display="flex" flexDirection="column" maxW="100%">
                                            <Dropzone
                                                minH="60px"
                                                w="100%"
                                                borderRadius="10px"
                                                mb="24px"
                                                onImageSelect={(files) => {
                                                    const newArchives = form?.values?.archives?.length > 0 ? [...form.values.archives, ...files] : files;
                                                    form.setFieldValue('archives', newArchives);
                                                }}
                                                accept="application/pdf,.doc,.docx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.presentationml.presentation,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.oasis.opendocument.text,.odt,application/vnd.oasis.opendocument.spreadsheet,.ods,application/vnd.oasis.opendocument.presentation,.odp,text/plain,.txt"
                                                multiple
                                                content={
                                                    <Box display="flex" flexDirection="column" gap="10px">
                                                        <Text color='blue.500' fontSize='xs' fontWeight='400'>Clique aqui para selecionar até 10 arquivos.</Text>
                                                        <Text color='secondaryGray.700' fontSize="10px" fontWeight='400'>Formatos aceitos: PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, ODT, ODS, ODP, TXT</Text>
                                                    </Box>
                                                }
                                            />
                                            <Grid templateColumns='repeat(3, 1fr)' gap='16px'>
                                                {form?.values?.archives?.length > 0 ? (
                                                    form.values.archives.map((archive, index) => (
                                                        <Flex key={index} padding="10px" display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly" position="relative" minW='120px' maxW="120px" minH='120px' maxH="120px" borderRadius="10px" border="1px" borderColor={borderColorArchive} background={bg}>
                                                            <Icon as={FaFileAlt} w='40px' h='40px' color={brandColor} />
                                                            <Text color='secondaryGray.700' fontSize='xs' fontWeight='400' wordBreak="break-all">{getNameWithExtensionFromUrl(archive)}</Text>
                                                            <Icon
                                                                onClick={() => {
                                                                    const updatedArchives = form.values.archives.filter((_, i) => i !== index);
                                                                    form.setFieldValue('archives', updatedArchives);
                                                                }}
                                                                as={MdClose}
                                                                cursor="pointer"
                                                                w='20px'
                                                                h='20px'
                                                                color="red.600"
                                                                position="absolute"
                                                                top="2px"
                                                                right="2px"
                                                                zIndex={2}
                                                            />
                                                        </Flex>
                                                    ))
                                                ) : null}
                                            </Grid>
                                        </Flex>
                                    )}
                                </Field>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="20px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    variant='brand'
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                    alignSelf="flex-end"
                                    w="150px"
                                    marginTop="24px"
                                >
                                    {infoRow ? 'Editar token' : 'Criar token'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='100px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "AÇÕES") {
                    return (
                        <Flex align='center' pl="30px" pt="5px">
                            <Menu row={cell.row.original} onClickEdit={onClickEdit} onClickBlock={onClickBlock} onClickDelete={onClickDelete} />
                        </Flex>
                    )
                  } else if (cell.column.Header === "NOME") {
                    data = (
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "QUANTIDADE") {
                    data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value}
                        </Text>
                    );
                  } else if (cell.column.Header === "VALOR") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {`R$ ${toPrice(cell.value, 2)}`}
                      </Text>
                    );
                  } else if (cell.column.Header === "REMUNERAÇÃO") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {`${cell.value}%`}
                      </Text>
                    );
                  } else if (cell.column.Header === "SITUAÇÃO") {
                    data = (
                      <Text color={cell.value ? 'red.500' : 'green.500'} fontSize='sm' fontWeight='700'>
                        {cell.value ? 'Inativo' : 'Ativo'}
                      </Text>
                    );
                  } else if (cell.column.Header === "CRIADO EM") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {lightFormat(new Date(cell.value), 'dd/MM/yyyy')}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
        />
        <ToastContainer />
    </Card>
  );
}
